export const GET_PACKAGE = "GET_PACKAGE";
export const GET_SINGLE_PACKAGE = "GET_SINGLE_PACKAGE";
export const ADD_PACKAGE = "ADD_PACKAGE";
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const DELETE_PACKAGE = "DELETE_PACKAGE";

export const GET_PACKAGE_ORDERS = "GET_PACKAGE_ORDERS";
export const CONFIRM_PACKAGE_ORDERS = "CONFIRM_PACKAGE_ORDERS";
export const DELETE_PACKAGE_ORDERS = "DELETE_PACKAGE_ORDERS";

// site
export const GET_POPULAR_PACKAGES = "GET_POPULAR_PACKAGES";
export const GET_SITE_PACKAGES = "GET_SITE_PACKAGES";
export const GET_SINGLE_PACKAGES = "GET_SINGLE_PACKAGES";
export const BOOK_PACKAGE = "BOOK_PACKAGE";
