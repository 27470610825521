<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    $startLoader() {
      let vm = this;
      vm.$store.state.isLoading = true;
    },
    $stopLoader() {
      let vm = this;
      vm.$store.state.isLoading = false;
    },

    $adminlogout() {
      let vm = this;
      localStorage.removeItem("maken_admin_access_token");
      localStorage.removeItem("maken_admin");
      vm.$router.push({ name: "AdminLogin" });
    },
    $loggedAdmin() {
      let vm = this;
      if (localStorage.getItem("maken_admin")) {
        let admin = JSON.parse(localStorage.getItem("maken_admin"));
        return admin;
      } else {
        return false;
      }
    },
    $loggedAdminToken() {
      let vm = this;
      if (localStorage.getItem("maken_admin_access_token")) {
        let admin_access_token = localStorage.getItem(
          "maken_admin_access_token"
        );
        return admin_access_token;
      } else {
        return false;
      }
    },
    $adminLogin(user, token) {
      localStorage.setItem("maken_admin_access_token", token);
      localStorage.setItem("maken_admin", JSON.stringify(user));
      this.$router.push({ name: "Dashboard" });
    },
    $clientLogout() {
      let vm = this;
      localStorage.removeItem("maken_client_access_token");
      localStorage.removeItem("maken_client");
      vm.$notify({
        group: "status",
        type: "success",
        title: "Important",
        text: "You are logged out now",
      });
      document.location.reload();
    },
    $loggedClient() {
      let vm = this;
      if (localStorage.getItem("maken_client")) {
        let client = JSON.parse(localStorage.getItem("client"));
        return client;
      } else {
        return false;
      }
    },
    $loggedClientToken() {
      let vm = this;
      if (localStorage.getItem("maken_client")) {
        let client_access_token = localStorage.getItem("client_access_token");
        return client_access_token;
      } else {
        return false;
      }
    },
    $isFieldsValidated(form, rules) {
      let vm = this;
      let is_error = false;
      Object.keys(rules).forEach((key, index) => {
        if (rules[key].run(form[key])) {
          let error_message = rules[key].run(form[key]);
          if (error_message) {
            if (!is_error) {
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: error_message,
              });
            }
            is_error = true;
          }
        }
      });
      return !is_error;
    },
    $img(img) {
      return `/img/${img}`;
    },

    $changePage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.getItems();
    },
    $previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getItems();
      }
    },
    $nextPage() {
      if (this.currentPage < this.pagesNumber) {
        this.currentPage++;
        this.getItems();
      }
    },
    $url(url) {
      return url.replace(/ /g, "-").toLowerCase();
    },
    $uploads(img) {
      return this.$store.state.apiUploadUrl + img;
    },
    $ago(date) {
      let now = new Date();
      let past = new Date(date);
      let diff = Math.abs(now - past);
      let minutes = Math.floor(diff / 60000);
      if (minutes < 60) {
        return minutes + " minutes ago";
      }
      let hours = Math.floor(minutes / 60);
      if (hours < 24) {
        return hours + " hours ago";
      }
      let days = Math.floor(hours / 24);
      if (days < 30) {
        return days + " days ago";
      }
      let months = Math.floor(days / 30);
      if (months < 12) {
        return months + " months ago";
      }
      let years = Math.floor(months / 12);
      return years + " years ago";
    },
    $date(date) {
      let d = new Date(date);
      return d.toDateString();
    },
  },
};
</script>
