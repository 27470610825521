<template>
  <div class="ViewPackage">
    <clientHeader />
    <div class="all-content" v-if="item">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              {{ item.title }}
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / Gallery /
              <strong>{{ item.title }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="view-package-widget">
        <div class="widget-header">
          <Carousel
            :wrapAround="true"
            :itemsToShow="2.1"
            :itemsToScroll="1"
            :transition="1000"
            :autoplay="2000"
            :snapAlign="'center'"
            v-if="item.images.length > 0"
            v-model="currentSlide"
            class="widget-body"
          >
            <CouSlide class="widget-item">
              <img :src="$uploads(item.image)" alt="" />
            </CouSlide>
            <CouSlide
              class="widget-item"
              v-for="image in item.images"
              :key="image"
            >
              <img :src="$uploads(image)" alt="" />
            </CouSlide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "ViewPackage",
  data() {
    return {
      isLoading: false,
      color: "#072e4d",
      fullPage: true,
      startBooking: false,
      Settings: {
        itemsToShow: 1,
        centerMode: false,
        autoPlay: true,
        wheelControl: false,
        trimWhiteSpace: true,
        infiniteScroll: true,
        playSpeed: 3000,
        transition: 1000,
        hoverPause: false,
        breakpoints: {
          400: {
            itemsToShow: 1.2,
          },
          800: {
            itemsToShow: 2.2,
          },
          1300: {
            itemsToShow: 3,
          },
          1600: {
            itemsToShow: 3,
          },
          2000: {
            itemsToShow: 4,
          },
        },
      },
      item: null,
      trips: [],
    };
  },
  methods: {
    onCancel() {
      console.log();
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SINGLE_GALLERY", this.$route.params.id)
        .then((response) => {
          this.item = response.data.album;
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
  watch: {
    "$route.params.id": function () {
      this.getItems();
    },
  },
};
</script>

<style lang="scss">
$orange: #ef6203;
$green: #044914;
.ViewPackage {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .view-package-widget {
    margin: 5rem;
    img {
      padding: 10px;
    }
  }
}
</style>
