<template>
  <div class="home">
    <clientHeader />
    <div class="all-content">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              Blog Posts
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / <strong>Blog</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="blog-posts">
          <div class="blog-posts-body">
            <router-link
              :to="{
                name: 'ViewBlog',
                params: { id: post.id },
                query: { title: $url(post.title) },
              }"
              class="blog-post"
              v-for="post in news"
              :key="post.id"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div class="img-section">
                <img :src="$uploads(post.image)" alt="" />
              </div>
              <div class="text-section">
                <label for="Blog" class="blog-title">{{ post.title }}</label>
                <p>{{ post.slug }}</p>
                <router-link
                  :to="{
                    name: 'ViewBlog',
                    params: { id: post.id },
                    query: { title: $url(post.title) },
                  }"
                  >Read More
                  <i class="fa-solid fa-arrow-right"></i>
                </router-link>
              </div>
            </router-link>
          </div>
          <div class="pagination">
            <button
              :class="{ disabled: currentPage === 1 }"
              @click="$previousPage()"
            >
              <label>Previous</label>
            </button>
            <p v-if="currentPage > 3">...</p>
            <button class="numbers" v-for="number in pagesNumber" :key="number">
              <label
                :class="{ active: currentPage === number }"
                for=""
                v-if="
                  (number == 1 && currentPage <= 2) ||
                  (currentPage + 2 >= pagesNumber &&
                    number >= pagesNumber - 2) ||
                  (number >= currentPage - 2 && number <= currentPage + 2)
                "
                @click="$changePage(number)"
              >
                {{ number }}
              </label>
            </button>
            <p v-if="currentPage <= pagesNumber - 3">...</p>
            <button
              :class="{ disabled: currentPage === pagesNumber.length }"
              @click="$nextPage()"
            >
              <label>Next</label>
            </button>
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      allItemsCount: 0,
      itemsPerpage: 12,
      pagesNumber: 1,
      currentPage: 1,
      news: [],
    };
  },
  methods: {
    prev() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.slides.length - 1;
      }
    },
    next() {
      // if (this.currentSlide < this.slides.length - 1) {
      this.currentSlide++;
      // } else {
      //   this.currentSlide = 0;
      // }
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BLOG", {
          paginate: 12,
        })
        .then((response) => {
          this.news = response.data.data;
          this.allItemsCount = response.data.total;
          this.pagesNumber = Math.ceil(this.allItemsCount / this.itemsPerpage);
          this.$stopLoader();
        })
        .catch((error) => {
          this.$stopLoader();
          this.$toast.error(error.response.data.message);
        });
    },
  },
  mounted() {
    let vm = this;
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.home {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .destinations {
    padding: 8rem 0;
    width: 100%;
    background: url("@/assets/images/bgs-01.png") no-repeat;
    .destinations-sections {
      gap: 1rem;
      margin: 2rem 0;
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .destination-item {
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0px 0px 10px 3px #4d4d4d2a;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        position: relative;
        cursor: pointer;
        .text-section {
          position: absolute;
          bottom: -400px;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 1rem;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          label {
            font-size: 1.5rem;
            font-weight: 600;
            color: white;
            display: flex;
            align-items: center;
            i {
              font-size: 1.5rem;
            }
          }
          p {
            font-size: 0.6rem;
            font-weight: 400;
            color: white;
            background: url("@/assets/images/bgo.svg") no-repeat;
            background-size: contain;
            padding: 1rem 2rem;
          }
        }
        .img-section {
          position: relative;
          height: 300px;
          width: 100%;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 1.5rem;
            @media screen and (max-width: 1300px) {
              height: 200px;
            }
            @media screen and (max-width: 1100px) {
              height: 250px;
            }
            @media screen and (max-width: 700px) {
              height: 200px;
            }
          }
        }
        .display-text {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          background: rgba(0, 0, 0, 0.233);
          p {
            font-size: 1.5rem;
            font-weight: 500;
            color: #fff;
            padding: 0rem 2rem;
          }
        }
        &:hover,
        &:focus {
          transform: scale(1, 1);
          z-index: 1;
          .text-section {
            transition: all 0.4s ease-in-out;
            bottom: 0px;
          }
          .display-text {
            display: none;
          }
        }
      }
      .view-all {
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0px 0px 10px 3px #4d4d4d2a;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        background: url("@/assets/images/bgs-03.png") no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover,
        &:focus {
          transform: scale(1, 1);
          z-index: 1;
        }
        .img-section {
          position: relative;
          img {
            height: 250px;
            width: 100%;
            object-fit: cover;
            border-radius: 1.5rem;
            @media screen and (max-width: 1300px) {
              height: 200px;
            }
            @media screen and (max-width: 1100px) {
              height: 250px;
            }
            @media screen and (max-width: 700px) {
              height: 200px;
            }
          }
        }
        .display-text {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          p {
            font-size: 1.2rem;
            font-weight: 500;
            color: #1d1d1d;
          }
        }
      }
    }
  }

  .packages {
    position: relative;
    background: url("@/assets/images/bgs-02.png") no-repeat;
    background-size: cover;
    background-size: cover;
    .search-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 2rem 0;
      input {
        width: 50%;
        padding: 1rem;
        border: none;
        border-radius: 5px 0px 5px 0px;
        outline: none;
        font-size: 1rem;
        border: 1px #d8d8d8 solid;
        @media screen and (max-width: 900px) {
          width: 80%;
        }
      }
      button {
        background: $green;
        border: none;
        border-radius: 0px 5px 5px 0px;
        padding: 1rem;
        cursor: pointer;
        i {
          font-size: 1.5rem;
          color: #fff;
        }
      }
    }
    .header {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      @media screen and (max-width: 600px) {
        align-items: flex-start;
      }
      .desc {
        color: #333333;
        font-weight: 400;
        font-size: 1.1rem;
        @media screen and (max-width: 1200px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 900px) {
          font-size: 0.8rem;
        }
      }
    }
    .packages-body {
      margin: 0rem 0;
    }
  }
  .for-pac {
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 1rem;
    padding: 0 8rem;
    @media screen and (max-width: 1100px) {
      grid-template-columns: 100%;
      padding: 0 2rem;
    }
    @media screen and (max-width: 900px) {
      padding: 0 1rem;
    }
    .right-filter {
      .filter {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 3px #4d4d4d11;
        margin-top: 4rem;
        .filter-header {
          padding: 1rem;
          border-bottom: 1px #eee solid;
          label {
            font-size: 1.5rem;
            font-weight: 600;
            color: #1d1d1d;
          }
        }
        .filter-body {
          padding: 1rem;
          .filter-item {
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px #eee solid;
            &:last-child {
              border-bottom: none;
            }
            label {
              font-size: 0.9rem;
              font-weight: 500;
              color: #1d1d1d;
            }
            .checkboxes {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #1d1d1d;
                margin-left: 0;
              }
            }
            input {
              width: 100%;
              padding: 0.45rem 1rem;
              border: none;
              border-radius: 5px;
              outline: none;
              font-size: 1rem;
              border: 1px #d8d8d8 solid;
              @media screen and (max-width: 900px) {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
}
</style>