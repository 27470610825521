<template>
  <div class="Attractions">
    <div class="admin-content">
      <adminSidebar />
      <div class="admin-body">
        <AdminLogged :brand="'Destinations'" />
        <div class="table">
          <div class="table-title">
            <label for="Attractions For Ren"></label>
            <button class="add-new" @click="addModal = true">
              <i class="fa fa-plus"></i> Add
            </button>
          </div>
          <table class="pads">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Country</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>
                  <img
                    :src="$uploads(item.images)"
                    alt="Destination"
                    width="50"
                    height="50"
                  />
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.country }}</td>
                <td>
                  <button class="edit" @click="startUpdate(item)">Edit</button>
                  <button class="delete" @click="startDelete(item)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination">
            <button
              :class="{ disabled: currentPage === 1 }"
              @click="$previousPage()"
            >
              <label>Previous</label>
            </button>
            <p v-if="currentPage > 3">...</p>
            <button class="numbers" v-for="number in pagesNumber" :key="number">
              <label
                :class="{ active: currentPage === number }"
                for=""
                v-if="
                  (number == 1 && currentPage <= 2) ||
                  (currentPage + 2 >= pagesNumber &&
                    number >= pagesNumber - 2) ||
                  (number >= currentPage - 2 && number <= currentPage + 2)
                "
                @click="$changePage(number)"
              >
                {{ number }}
              </label>
            </button>
            <p v-if="currentPage <= pagesNumber - 3">...</p>
            <button
              :class="{ disabled: currentPage === pagesNumber.length }"
              @click="$nextPage()"
            >
              <label>Next</label>
            </button>
          </div>
        </div>
        <Model v-if="addModal == true">
          <div class="model-header">
            <h4>Add Destination</h4>
            <button class="close" @click="addModal = false">X</button>
          </div>
          <div class="model-body">
            <form>
              <div class="form-group">
                <label for="Name">Name:</label>
                <input
                  type="text"
                  Name="Name"
                  placeholder="Name"
                  v-model="newItem.name"
                />
              </div>
              <div class="form-group">
                <label for="Country">Country:</label>
                <input
                  type="text"
                  id=""
                  placeholder="Country"
                  v-model="newItem.country"
                />
              </div>
              <div class="form-group">
                <label for="email address">Image</label>
                <o-upload
                  :multiple="false"
                  @imagesUploaded="
                    (data) => {
                      newItem.images = data;
                    }
                  "
                  @imagesUploadedFailed="
                    (data) => {
                      console.log('failed');
                    }
                  "
                  :value="newItem.images"
                  @startLoader="() => $startLoader()"
                  @stopLoader="() => $stopLoader()"
                />
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="addItems()">Submit</button>
            <button class="close" @click="addModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="updateModal == true">
          <div class="model-header">
            <h4>Edit Destination</h4>
            <button class="close" @click="updateModal = false">X</button>
          </div>
          <div class="model-body">
            <form>
              <div class="form-group">
                <label for="Name">Name:</label>
                <input
                  type="text"
                  Name="Name"
                  placeholder="Name"
                  v-model="selectedItem.name"
                />
              </div>
              <div class="form-group">
                <label for="Country">Country:</label>
                <input
                  type="text"
                  id=""
                  placeholder="Country"
                  v-model="selectedItem.country"
                />
              </div>
              <div class="form-group">
                <label for="email address">Images</label>
                <br />
                <o-upload
                  :multiple="false"
                  @imagesUploaded="
                    (data) => {
                      selectedItem.images = data;
                    }
                  "
                  @imagesUploadedFailed="
                    (data) => {
                      console.log('failed');
                    }
                  "
                  :value="selectedItem.images"
                  @startLoader="() => $startLoader()"
                  @stopLoader="() => $stopLoader()"
                />
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="updateItems()">
              Update
            </button>
            <button class="close" @click="updateModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="deleteModal == true">
          <div class="model-header">
            <h4>Delete Attraction</h4>
            <button class="close" @click="deleteModal = false">X</button>
          </div>
          <div class="model-body">
            <h4 class="delete-label">
              Are you sure you want to delete
              <strong>{{ selectedItem.name }}</strong
              >?
            </h4>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="deleteItems()">Yes</button>
            <button class="close" @click="deleteModal = false">Cancel</button>
          </div>
        </Model>
      </div>
    </div>
  </div>
</template>

<script>
import Model from "./components/Model.vue";
export default {
  name: "Attractions",
  components: {
    Model,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      color: "#015b0a",
      addModal: false,
      updateModal: false,
      deleteModal: false,
      newItem: {},
      selectedItem: {},
      items: [],
      token: null,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
    };
  },
  methods: {
    onCancel() {
      console.log();
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ATTRACTION", {
          token: this.$loggedAdminToken(),
          paginate: this.itemsPerpage,
          page: this.currentPage,
        })
        .then((response) => {
          this.items = response.data.data;
          this.allItemsCount = response.data.total;
          this.pagesNumber = Math.ceil(this.allItemsCount / this.itemsPerpage);
          this.$stopLoader();
        });
    },
    addItems() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_ATTRACTION", this.newItem)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.addModal = false;
            this.newItem.name = "";
            this.newItem.country = "";
            this.newItem.region = "";
            this.newItem.imagess = "";
            this.getItems();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
      this.$stopLoader();
    },
    startUpdate(item) {
      this.selectedItem = item;
      this.updateModal = true;
    },
    updateItems() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_ATTRACTION", this.selectedItem)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.updateModal = false;
            this.selectedItem = "";
            this.getItems();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
      this.$stopLoader();
    },
    startDelete(item) {
      this.selectedItem = item;
      this.deleteModal = true;
    },
    deleteItems() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ATTRACTION", this.selectedItem.id)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.deleteModal = false;
            this.selectedItem = "";
            this.getItems();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
      this.$stopLoader();
    },
  },
  mounted() {
    this.getItems();
    if (this.$loggedAdmin()) {
      this.token = this.$loggedAdminToken();
    } else if (this.$loggedClient()) {
      this.token = this.$loggedClientToken();
    } else {
      this.token = null;
    }
  },
};
</script>

<style lang="scss" scoped></style>
