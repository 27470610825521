<template>
  <div class="clientHeader">
    <div class="header-content">
      <section class="topbar">
        <router-link to="/" class="left">
          <img
            src="@/assets/images/logot.png"
            alt="Maken Africa Safaris"
            srcset=""
          />
        </router-link>
        <div class="right">
          <div class="navbar">
            <ul class="nav-links">
              <li class="link-item">
                <router-link to="/" class="link-a">Home</router-link>
                <!-- <router-link to="/services" class="link-a"
                  >Services</router-link
                > -->
                <router-link to="/aboutus" class="link-a">About</router-link>
                <router-link to="/destinations" class="link-a"
                  >Destinations</router-link
                >
                <router-link to="/itineraries" class="link-a"
                  >ITINERARIES</router-link
                >
                <router-link :to="{ name: 'Blog' }" class="link-a"
                  >Blog</router-link
                >
                <router-link to="/gallery" class="link-a">Gallery</router-link>
                <!-- <router-link to="/trips" class="link-a">Trips</router-link> -->
                <router-link to="/contact" class="link-a">Contact</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-right">
          <div class="contact">
            <i class="fa-solid fa-paper-plane"></i>
            <div class="text">
              <p>For More Enquiries</p>
              <label>+250 788 232 465</label>
            </div>
          </div>
        </div>

        <button class="lines" @click="leftbar = true">
          <i class="fas fa-bars"></i>
        </button>
      </section>
      <section class="sidebar" v-if="leftbar">
        <div class="sidebar-body">
          <div class="sidebar-header">
            <label for="Maken Africa Safaris">Maken Africa Safaris</label>
            <button @click="leftbar = false">&times;</button>
          </div>
          <ul class="side-links">
            <li><router-link class="side-link" to="/">Home</router-link></li>
            <li>
              <router-link class="side-link" to="/aboutus">About</router-link>
            </li>
            <li>
              <router-link class="side-link" to="/destinations"
                >Destinations</router-link
              >
            </li>
            <li>
              <router-link class="side-link" to="/itineraries"
                >Itineraries</router-link
              >
            </li>
            <li>
              <router-link class="side-link" to="/blog">Blog</router-link>
            </li>
            <li>
              <router-link class="side-link" to="/gallery">Gallery</router-link>
            </li>
            <li>
              <router-link class="side-link" to="/contact">Contact</router-link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "clientHeader",
  data() {
    return {
      leftbar: false,
    };
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
$orange: #ef6203;
$green: #044914;
* {
  transition: all 0.4s ease-in;
}
.clientHeader {
  width: 99.1vw;
  overflow: hidden;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  // box-shadow: 0px 1px 10px 1px #00000034;
  .header-content {
    .topbar {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 15rem;
      align-items: center;
      @media screen and (max-width: 1700px) {
        padding: 1rem 10rem;
      }
      @media screen and (max-width: 1500px) {
        padding: 1rem 8rem;
      }
      @media screen and (max-width: 1300px) {
        padding: 1rem 4rem;
      }
      @media screen and (max-width: 900px) {
        padding: 1rem 2rem;
      }
      .left {
        img {
          width: 150px;
          @media screen and (max-width: 1700px) {
            width: 120px;
          }
          @media screen and (max-width: 1500px) {
            width: 100px;
          }
          @media screen and (max-width: 1200px) {
            width: 80px;
          }
        }
      }
      .right {
        margin-bottom: -10px;
        @media screen and (max-width: 750px) {
          display: none;
        }
        .navbar {
          .nav-links {
            list-style: none;
            display: flex;
            .link-item {
              .link-a {
                padding: 0.8rem 1rem;
                color: #000;
                font-weight: 500;
                position: relative;
                text-transform: uppercase;
                @media screen and (max-width: 1500px) {
                  padding: 0.8rem 0.75rem;
                  font-size: 0.8rem;
                }
                @media screen and (max-width: 1000px) {
                  padding: 0.8rem 0.5rem;
                  font-size: 0.9rem;
                }
                &:hover {
                  color: $green;
                }
                &:hover:after {
                  color: $green;
                  transform: scaleX(1);
                  transform-origin: bottom left;
                }
                &.router-link-exact-active {
                  color: $green;
                }
                &:after {
                  content: "";
                  position: absolute;
                  width: 100%;
                  transform: scaleX(0);
                  height: 1px;
                  bottom: 0;
                  left: 0;
                  transform-origin: bottom right;
                  transition: transform 0.4s ease-in-out;
                }
              }
            }
          }
        }
      }
      .right-right {
        display: block;
        .contact {
          display: flex;
          align-items: center;
          i {
            font-size: 1.5rem;
            color: $green;
            border-right: 1px #979797 solid;
            padding-right: 0.5rem;
            @media screen and (max-width: 1500px) {
              font-size: 1.2rem;
            }
          }
          .text {
            margin-left: 0.5rem;
            p {
              font-size: 0.8rem;
              margin: 0;
              color: #000;
              @media screen and (max-width: 1500px) {
                font-size: 0.7rem;
                line-height: 1;
              }
            }
            label {
              font-size: 1rem;
              color: $green;
              font-weight: 700;
              @media screen and (max-width: 1500px) {
                font-size: 0.8rem;
              }
            }
          }
        }
        @media screen and (max-width: 750px) {
          display: none;
        }
      }
      .lines {
        background: none;
        font-size: 1.25rem;
        border: none;
        outline: none;
        background: $green;
        padding: 0 10px;
        box-shadow: 0px 0px 5px 10px #00000025 inset;
        color: white;
        border-radius: 3px;
        cursor: pointer;
        @media screen and (min-width: 750px) {
          display: none;
        }
      }
    }
    .sidebar {
      position: fixed;
      z-index: 99;
      background: white;
      width: 50vw;
      height: 100vh;
      top: 0;
      left: 0;
      box-shadow: 5px 0px 10px 10px #0000005b;
      @media screen and (min-width: 750px) {
        display: none;
      }
      @media screen and (max-width: 450px) {
        width: 80vw;
      }
      .sidebar-body {
        display: flex;
        flex-direction: column;
        .sidebar-header {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          align-items: center;
          label {
            font-size: 0.9rem;
          }
          button {
            background: none;
            font-size: 1.25rem;
            border: none;
            outline: none;
            background: #eee;
            padding: 0 10px;
            box-shadow: 0px 0px 5px 10px #00000025 inset;
            cursor: pointer;
          }
        }
        .side-links {
          list-style: none;
          display: flex;
          flex-direction: column;
          li {
            display: block;
            .side-link {
              padding: 0.5rem 1rem;
              color: #00051b;
              font-size: 0.9rem;
              display: block;
              border-bottom: 1px #eee solid;
              &:hover {
                background: $green;
                color: white;
              }
              &.router-link-exact-active {
                background: $green;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
</style>
