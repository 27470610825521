
import axios from 'axios'
import { BASE_URL } from '../variables';
const UPLOADIMAGES_URL = '/upload';
const PROCESS_IMAGE_URL = '/processImage';

const files = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['UPLOADIMAGES']: (state, payload) => {
            return axios({
                url: BASE_URL + UPLOADIMAGES_URL,
                method: "POST",
                data: payload,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
        },
        ['PROCESS_IMAGE']: (state, payload) => {
            return axios({
                url: BASE_URL + PROCESS_IMAGE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
        },
    },
    modules: {},
}
export default files;