<template>
  <div class="home">
    <clientHeader />
    <div class="all-content" v-if="selectedPackage">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              Package Details
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / Itineraries / <strong>{{ selectedPackage.title }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="package-details-section">
          <div class="image-section">
            <div class="main-img">
              <img
                :src="$uploads(selectedPackage.image)"
                :alt="selectedPackage.attraction.name"
                srcset=""
              />
            </div>
            <div class="other-images">
              <div
                class="img-item"
                v-for="(image, index) in [...selectedPackage.images].splice(
                  0,
                  3
                )"
                :key="index"
              >
                <img
                  :src="$uploads(image)"
                  :alt="selectedPackage.attraction.name"
                  srcset=""
                />
              </div>
              <div
                class="img-item"
                v-if="
                  selectedPackage.images && selectedPackage.images.length > 4
                "
              >
                <img
                  :src="
                    $uploads(
                      selectedPackage.images[selectedPackage.images.length - 1]
                    )
                  "
                  :alt="selectedPackage.attraction.name"
                  srcset=""
                />
                <div class="more">
                  <label for="More">
                    <i class="fas fa-plus"></i>
                    <span> View More Images </span>
                  </label>
                </div>
              </div>
              <div class="img-item" v-else>
                <img
                  :src="$uploads(selectedPackage.images[3])"
                  :alt="selectedPackage.attraction.name"
                  srcset=""
                  v-if="index < 3"
                />
              </div>
            </div>
          </div>
          <div class="text-content-section">
            <div class="left-content-section">
              <div class="detail-header-section">
                <h2>
                  {{ selectedPackage.title }}
                </h2>
                <div class="price">
                  <label for="Amount">Starting From: </label>
                  <p>
                    ${{ selectedPackage.price }} /
                    <span>per person</span>
                  </p>
                </div>
                <div class="durations">
                  <div class="duration-item">
                    <i class="fas fa-clock"></i>
                    <label for="Duration">{{ selectedPackage.duration }}</label>
                  </div>
                  <div class="duration-item">
                    <i class="fa fa-users"></i>
                    <label for="Duration"
                      >Max People: {{ selectedPackage.max_people }}</label
                    >
                  </div>
                  <div class="duration-item">
                    <i class="fas fa-map-marker-alt"></i>
                    <label for="Duration">
                      {{ selectedPackage.attraction.country }}
                    </label>
                  </div>

                  <!-- <div class="duration-item">
                    <i class="fa-solid fa-layer-group"></i>
                    <label for="Duration">
                      Tour Type:
                      <span
                        v-for="(category, index) in selectedPackage.category"
                        :key="index"
                        >{{ category }},&nbsp;</span
                      >
                    </label>
                  </div> -->
                </div>
              </div>
              <div class="overview detail-item-list-item">
                <p v-html="selectedPackage.description"></p>
              </div>
              <div
                class="included-excluded detail-item-list-item"
                v-if="
                  (selectedPackage.inclusives &&
                    selectedPackage.inclusives.length > 0) ||
                  (selectedPackage.exclusives &&
                    selectedPackage.exclusives.length > 0)
                "
              >
                <div class="detail-item-header">Included and Excluded</div>
                <div class="dflex">
                  <div
                    class="included"
                    v-if="
                      selectedPackage.inclusives &&
                      selectedPackage.inclusives.length > 0
                    "
                  >
                    <ul>
                      <li
                        v-for="(item, index) in selectedPackage.inclusives"
                        :key="index"
                      >
                        <i class="fas fa-check"></i>
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                  <div
                    class="excluded"
                    v-if="
                      selectedPackage.exclusives &&
                      selectedPackage.exclusives.length > 0
                    "
                  >
                    <ul>
                      <li
                        v-for="(item, index) in selectedPackage.exclusives"
                        :key="index"
                      >
                        <i class="fas fa-times"></i>
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="itinerary detail-item-list-item"
                v-if="selectedPackage.days && selectedPackage.days.length > 0"
              >
                <div class="detail-item-header">Highlights of the Tour</div>
                <div
                  class="itinerary-item"
                  :class="{ active: selectedIt.includes(index) }"
                  @click="selectIt(index)"
                  v-for="(day, index) in selectedPackage.days"
                  :key="index"
                >
                  <div class="itinerary-header">
                    <label for="">Day 0{{ index + 1 }}:</label>
                    <div class="right">
                      <label for="">{{ day.title }}</label>
                      <i class="fas fa-angle-down"></i>
                    </div>
                  </div>
                  <div class=".itinerary-right">
                    <div class="itinerary-right-body">
                      <p v-html="day.description"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="detail-item-list-item">
                <div class="detail-item-header">Location on the map</div>

                <iframe
                  :src="
                    'https://www.google.com/maps?q=' +
                    selectedPackage.location +
                    '&output=embed'
                  "
                ></iframe>
              </div>
            </div>
            <div class="right-content-section">
              <div class="right-form">
                <div class="form-header">
                  <h3>Book Your Trip</h3>
                  <p>
                    Reserve your ideal trip early for a hassle-free trip; secure
                    comfort and convenience!
                  </p>
                </div>
                <div class="form-body">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="Name">Tour date</label>
                      <a-date-picker
                        v-model:value="booking.date"
                        placeholder="When would you like to tour"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label for="Name">Number of people</label>
                      <a-input-number
                        v-model:value="booking.people"
                        min="1"
                        max="10"
                        placeholder="How many people are you booking for"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label for="Name">Your Name</label>
                      <a-input
                        v-model:value="booking.fullname"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label for="Name">Your Email</label>
                      <a-input
                        v-model:value="booking.email"
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label for="Name">Your Phone</label>
                      <a-input
                        v-model:value="booking.phone"
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label for="Name">Your Message</label>
                      <a-textarea
                        v-model:value="booking.details"
                        placeholder="Enter your message"
                        rows="5"
                      />
                    </div>
                  </div>
                  <div class="estimates">
                    <label for="Estimates"
                      >Estimated Price:
                      <strong
                        >$
                        {{ selectedPackage.price * booking.people }}
                      </strong></label
                    >
                    <span>
                      <i class="fas fa-info-circle"></i>
                      <label for="Estimates"
                        >The price is an estimate and may vary depending on the
                        number of people and the time of booking. we will
                        confirm with you the final price via email</label
                      >
                    </span>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <button class="my-btn" @click="sendBooking()">
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="packages">
          <div class="section-header">
            <label for="Destinations" class="my-title"
              >Related Travel Packages</label
            >
            <p class="desc">
              Explore our popular travel packages and book your next trip with
              us.
            </p>
          </div>
          <div
            class="packages-body"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <router-link
              :to="'/viewPackage/' + item.id"
              class="package-item"
              v-for="item in packages"
              :key="item.id"
            >
              <div class="content">
                <div class="img-section jc-center">
                  <img
                    :src="$uploads(item.image)"
                    :alt="item.attraction.name"
                    srcset=""
                  />
                  <div class="specs">
                    <div class="region">
                      <i class="fas fa-map-marker-alt"></i>
                      <label for="Tourism Region">{{
                        item.attraction.country
                      }}</label>
                    </div>
                    <div class="days">
                      <label for="Days">{{ item.duration }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-section">
                  <label :for="item.attraction.name" class="package-title">{{
                    item.title
                  }}</label>
                </div>
                <hr />
                <div class="content-footer">
                  <div class="money">
                    <label
                      for="Amount"
                      v-if="item.price && item.price.length > 0"
                    >
                      <span>Starting From: </span>
                      <p>${{ item.price }}</p></label
                    >
                    <label for="Amount" v-else>-</label>
                  </div>
                  <div class="book">
                    <router-link
                      :to="'/viewPackage/' + item.id"
                      class="book-btn"
                      >Book A Trip
                      <i class="fa-solid fa-suitcase-rolling"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isLoading: false,
      color: "$green",
      fullPage: true,
      packages: [],
      trips: [],
      partners: [],
      currentSlide: 0,
      activetab: 1,
      allItemsCount: 100,
      itemsPerpage: 10,
      pagesNumber: 3,
      currentPage: 1,
      selectedPackage: null,
      selectedId: null,
      selectedIt: [1],
      total_amount: 0,
      booking: {
        fullname: "",
        email: "",
        phone: "",
        message: "",
        date: "",
        people: 1,
        package_id: null,
      },
      doneBooking: false,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SINGLE_PACKAGE", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.packages = response.data.related;
          this.selectedPackage = response.data.package;
          this.$stopLoader();
        })
        .catch((error) => {
          this.$stopLoader();
        });
    },
    selectIt(id) {
      if (this.selectedIt.includes(id)) {
        this.selectedIt = this.selectedIt.filter((item) => item !== id);
      } else {
        this.selectedIt.push(id);
      }
    },
    sendBooking() {
      this.$startLoader();
      this.$store
        .dispatch("BOOK_PACKAGE", this.booking)
        .then((response) => {
          this.$stopLoader();
          this.$success({
            title: "Success",
            content:
              "Your booking has been received. Sit back and relax, our team will get back to you shortly",
          });
          this.booking = {
            fullname: "",
            email: "",
            phone: "",
            message: "",
            date: "",
            people: 1,
            package_id: this.selectedId,
          };
          this.doneBooking = true;
        })
        .catch((error) => {
          this.$stopLoader();
          this.$error({
            title: "Error",
            content: "An error occurred while processing your request",
          });
        });
    },
  },
  mounted() {
    let vm = this;
    this.getItems();
    this.selectedId = this.$route.params.id;
    this.booking.package_id = this.selectedId;
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.home {
  width: 99.1vw;
  overflow: hidden;
  background: #fbffff;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }

  .package-details-section {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    .image-section {
      display: grid;
      grid-template-columns: 5fr 3fr;
      gap: 1rem;
      background: #ffffff;
      border-radius: 10px;
      padding: 1rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      .main-img {
        width: 100%;
        height: 500px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .other-images {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        .img-item {
          height: 240px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
          .more {
            label {
              top: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.5);
              color: #fff;
              padding: 0.5rem;
              border-radius: 5px;
              position: absolute;
              cursor: pointer;
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 0.5rem;
              i {
                margin-right: 0.5rem;
                display: block;
                font-size: 1.5rem;
                color: #fff;
              }
              span {
                font-size: 0.95rem;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .text-content-section {
      display: grid;
      grid-template-columns: 70% 30%;
      margin-top: 3rem;
      gap: 2rem;
      .left-content-section {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .detail-header-section {
          h2 {
            font-size: 2.2rem;
            font-weight: 500;
            color: #000;
          }
          .price {
            label {
              font-size: 0.7rem;
              font-weight: 400;
              color: #000;
            }
            p {
              font-size: 1.75rem;
              font-weight: 600;
              color: $green;
              span {
                font-size: 1.1rem;
                font-weight: 400;
                color: #444;
              }
            }
          }
          .durations {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1rem;
            .duration-item {
              display: flex;
              align-items: center;
              i {
                font-size: 1rem;
                margin-right: 0.5rem;
                color: $green;
              }
              label {
                font-size: 1rem;
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
        .detail-item-header {
          font-size: 1.5rem;
          font-weight: 500;
          color: #000;
          margin-bottom: 1rem;
        }
        .overview {
          font-size: 1rem;
          font-weight: 400;
          color: #555;
          line-height: 1.75;
        }
        .included-excluded {
          .dflex {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            .included,
            .excluded {
              h3 {
                font-size: 1.2rem;
                font-weight: 500;
                color: #000;
              }
              ul {
                list-style: none;
                padding: 0;
                li {
                  display: flex;
                  gap: 0.5rem;
                  margin-bottom: 0.5rem;
                  color: #555;
                  i {
                    font-size: 1rem;
                    color: $green;
                    &.fa-times {
                      color: #ff0000;
                    }
                  }
                }
              }
            }
          }
        }
        .itinerary {
          .itinerary-item {
            border-radius: 10px;
            margin-top: 1rem;
            .itinerary-header {
              display: grid;
              grid-template-columns: 100px calc(100% - 100px);
              align-items: center;
              border-bottom: 1px solid #f1f1f1;
              overflow: hidden;
              border: 1px solid #f1f1f1;
              border-radius: 10px;
              cursor: pointer;
              label {
                padding: 1rem;
                font-size: 1.2rem;
                font-weight: 500;
                color: #fff;
                background: $green;
                border-radius: 2px 0 0 2px;
              }
              .right {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 0.5rem;
                padding: 0 1rem;
                label {
                  font-size: 1.15rem;
                  font-weight: 600;
                  color: #000;
                  border-radius: 0;
                  padding: 0;
                  background: transparent;
                }
                i {
                  font-size: 1.5rem;
                  color: $green;
                }
              }
            }
            .itinerary-right-body {
              padding: 0rem 2rem;
              height: 0;
              overflow: hidden;
              margin-left: 1rem;
              p {
                font-size: 1rem;
                font-weight: 400;
                color: #555;
                line-height: 1.75;
              }
              ul {
                list-style: square;
                padding: 0;
                li {
                  display: flex;
                  gap: 0.5rem;
                  margin-bottom: 0.5rem;
                  color: #555;
                  i {
                    font-size: 1rem;
                    color: $green;
                    &.fa-times {
                      color: #ff0000;
                    }
                  }
                }
              }
            }
            &.active {
              .itinerary-right-body {
                height: fit-content;
                padding: 1rem 2rem;
                transition: all 0.5s;
              }
            }
          }
        }
        .detail-item-list-item {
          iframe {
            width: 100%;
            height: 400px;
            border: none;
            border-radius: 10px;
          }
        }
      }
      .right-content-section {
        .right-form {
          background: #f0f3eb;
          border-radius: 2px;
          padding: 1rem;
          // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          .form-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid #dadada;
            h3 {
              font-size: 1.5rem;
              font-weight: 500;
              color: #000;
            }
            p {
              font-size: 1rem;
              font-weight: 400;
              color: #555;
              line-height: 1.5;
              text-align: center;
            }
          }
          .form-body {
            .form-group {
              margin-bottom: 1rem;
              .input-group {
                label {
                  font-size: 1rem;
                  font-weight: 500;
                  color: #000;
                  margin-bottom: 0.5rem;
                  display: block;
                  width: 100%;
                }
                input,
                textarea {
                  width: 100%;
                  padding: 0.5rem;
                  border: 1px solid #f1f1f1;
                  border-radius: 5px;
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #555;
                  outline: none;
                }
                div {
                  width: 100%;
                }
              }
            }
            .estimates {
              border-top: 1px solid #dadada;
              border-bottom: 1px solid #dadada;
              padding: 1rem 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;
              label {
                font-size: 1rem;
                font-weight: 500;
                color: #000;
                strong {
                  font-size: 1.5rem;
                  font-weight: 700;
                  color: #000;
                }
              }
              span {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                border: 1px solid #f1f1f1;
                padding: 0.5rem;
                i {
                  font-size: 2rem;
                  color: $green;
                  border-right: 1px solid #f1f1f1;
                  padding-right: 0.5rem;
                }
                label {
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #555;
                }
              }
            }
            .form-group {
              margin-top: 1rem;
              .input-group {
                button {
                  width: 100%;
                  padding: 0.5rem;
                  background: $green;
                  color: #fff;
                  font-size: 1rem;
                  font-weight: 500;
                  border: none;
                  border-radius: 5px;
                  cursor: pointer;
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
