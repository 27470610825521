<template>
  <div class="home">
    <clientHeader />
    <div class="all-content" v-if="selectedItem">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              {{ selectedItem.title }}
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / Blog / <strong>{{ selectedItem.title }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="view-blog-post">
          <div class="img">
            <img :src="$uploads(selectedItem.image)" alt="" />
          </div>
          <div class="text">
            <h2>{{ selectedItem.title }}</h2>
            <p>
              <span>
                <i class="fa-solid fa-calendar"></i>
                {{ $ago(selectedItem.created_at) }}
              </span>
              <span>
                <i class="fa-solid fa-user"></i>
                {{ selectedItem.author }}
              </span>
            </p>
          </div>
          <div class="text-content">
            <p v-html="selectedItem.description"></p>
          </div>
        </div>
      </div>
      <div class="container for-with">
        <div class="blog-posts">
          <div class="section-header">
            <label for="Blog" class="my-title">Related Blog Posts</label>
            <p class="desc">
              Read our latest blog posts and stay updated with the latest travel
              news.
            </p>
          </div>
          <div class="blog-posts-body">
            <router-link
              :to="{
                name: 'ViewBlog',
                params: { id: post.id },
                query: { title: $url(post.title) },
              }"
              class="blog-post"
              v-for="post in news"
              :key="post.id"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div class="img-section">
                <img :src="$uploads(post.image)" alt="" />
              </div>
              <div class="text-section">
                <label for="Blog" class="blog-title">{{ post.title }}</label>
                <p>{{ post.slug }}</p>
                <router-link
                  :to="{
                    name: 'ViewBlog',
                    params: { id: post.id },
                    query: { title: $url(post.title) },
                  }"
                  >Read More
                  <i class="fa-solid fa-arrow-right"></i>
                </router-link>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      allItemsCount: 100,
      itemsPerpage: 10,
      pagesNumber: 3,
      currentPage: 1,
      news: [],
      selectedItem: null,
    };
  },
  methods: {
    prev() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.slides.length - 1;
      }
    },
    next() {
      // if (this.currentSlide < this.slides.length - 1) {
      this.currentSlide++;
      // } else {
      //   this.currentSlide = 0;
      // }
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SINGLE_BLOG", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.news = response.data.related;
          this.selectedItem = response.data;
          this.$stopLoader();
        })
        .catch((error) => {
          this.$stopLoader();
          this.$toast.error(error.response.data.message);
        });
    },
  },
  mounted() {
    let vm = this;
    let activeId = this.$route.params.id;
    if (activeId) {
      this.selectedItem = this.news.find((item) => item.id == activeId);
    }
    this.getItems();
  },
  watch: {
    $route(to, from) {
      this.getItems();
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.home {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .view-blog-post {
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
    padding-bottom: 5rem;
    .img {
      img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        @media screen and (max-width: 900px) {
          height: 400px;
        }
        @media screen and (max-width: 600px) {
          height: 300px;
        }
      }
    }
    .text {
      padding: 20px 0;
      h2 {
        font-size: 2.5rem;
        font-weight: 500;
        color: $green;
        @media screen and (max-width: 900px) {
          font-size: 2rem;
        }
        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }
      p {
        display: flex;
        justify-content: space-between;
        span {
          margin-right: 20px;
          color: #888;
          font-style: italic;
          font-size: 0.85rem;
          i {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
