export const GET_USERS = "GET_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const ADD_USER = "ADD_USER";
export const GET_AGENT_ACCOUNT = "GET_AGENT_ACCOUNT";
export const UPDATE_AGENT_ACCOUNT = "UPDATE_AGENT_ACCOUNT";
export const UPDATE_AGENT_PASSWORD = "UPDATE_AGENT_PASSWORD";

export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const DASHBOARDS = "DASHBOARDS";
