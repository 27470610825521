<template>
  <div class="home">
    <clientHeader />
    <div class="all-content">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              Destinations
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / <strong>Destinations</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="destinations">
          <!-- <div class="search-input">
            <input type="text" placeholder="Search Destinations" />
            <button>
              <i class="fas fa-search"></i>
            </button>
          </div> -->
          <div class="destinations-sections">
            <div
              class="destination-item"
              v-for="destination in destinations"
              :key="destination.id"
            >
              <div class="img-section">
                <img :src="$uploads(destination.images)" alt="" />
              </div>
              <div class="text-section on-hover">
                <label for="Destination">{{ destination.name }}</label>
                <p>{{ destination.packages }} Tours</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="packages">
          <div class="tag-header">
            <p>Experience</p>
          </div>
          <div class="section-header">
            <label for="Destinations" class="my-title"
              >Popular Travel Packages</label
            >
            <p class="desc">
              Explore our popular travel packages and book your next trip with
              us.
            </p>
          </div>
          <div
            class="packages-body"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <router-link
              :to="'/viewPackage/' + item.id"
              class="package-item"
              v-for="item in packages"
              :key="item.id"
            >
              <div class="content">
                <div class="img-section jc-center">
                  <img
                    :src="$uploads(item.image)"
                    :alt="item.attraction.name"
                    srcset=""
                  />
                  <div class="specs">
                    <div class="region">
                      <i class="fas fa-map-marker-alt"></i>
                      <label for="Tourism Region">{{
                        item.attraction.country
                      }}</label>
                    </div>
                    <div class="days">
                      <label for="Days">{{ item.duration }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-section">
                  <label :for="item.attraction.name" class="package-title">{{
                    item.title
                  }}</label>
                </div>
                <hr />
                <div class="content-footer">
                  <div class="money">
                    <label for="Amount" v-if="item.price">
                      <span>Starting From: </span>
                      <p>${{ item.price }}</p></label
                    >
                    <label for="Amount" v-else>-</label>
                  </div>
                  <div class="book">
                    <router-link
                      :to="'/viewPackage/' + item.id"
                      class="book-btn"
                      >Book A Trip
                      <i class="fa-solid fa-suitcase-rolling"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class="packages-footer jc-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <router-link to="/packages" class="mybtn-o"
              >View More Packages</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isLoading: false,
      color: "#072e4d",
      fullPage: true,
      packages: [],
      trips: [],
      partners: [],
      currentSlide: 0,
      destinations: [],
      activetab: 1,
    };
  },
  methods: {
    prev() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.slides.length - 1;
      }
    },
    next() {
      // if (this.currentSlide < this.slides.length - 1) {
      this.currentSlide++;
      // } else {
      //   this.currentSlide = 0;
      // }
    },
    getPackages() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PACKAGE", {
          paginate: 3,
          page: 1,
        })
        .then((response) => {
          this.packages = response.data.packages.data;
          this.$stopLoader();
        });
    },

    getDestinations() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ATTRACTION", {
          paginate: 100,
        })
        .then((response) => {
          this.destinations = response.data.data;
          this.$stopLoader();
        })
        .catch((error) => {
          this.$stopLoader();
        });
    },
  },
  mounted() {
    let vm = this;
    this.getPackages();
    this.getDestinations();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.home {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .destinations {
    padding: 8rem 0;
    width: 100%;
    background: url("@/assets/images/bgs-01.png") no-repeat;
    background-size: cover;
    @media screen and (max-width: 900px) {
      padding: 4rem 0;
    }
    @media screen and (max-width: 600px) {
      padding: 2rem 0;
    }
    .search-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 2rem 0;
      input {
        width: 50%;
        padding: 1rem;
        border: none;
        border-radius: 5px 0px 5px 0px;
        outline: none;
        font-size: 1rem;
        border: 1px #d8d8d8 solid;
        @media screen and (max-width: 900px) {
          width: 80%;
        }
      }
      button {
        background: $green;
        border: none;
        border-radius: 0px 5px 5px 0px;
        padding: 1rem;
        cursor: pointer;
        i {
          font-size: 1.5rem;
          color: #fff;
        }
      }
    }
    .destinations-sections {
      gap: 1rem;
      margin: 2rem 0;
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 500px) {
        grid-template-columns: 100%;
      }
      .destination-item {
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 3px #4d4d4d2a;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        position: relative;
        cursor: pointer;
        height: 400px;
        @media screen and (max-width: 1300px) {
          height: 300px;
        }
        @media screen and (max-width: 1100px) {
          height: 275px;
        }
        @media screen and (max-width: 700px) {
          height: 250px;
        }
        .text-section {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 1rem;
          background: rgba(0, 0, 0, 0.5);
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.712) 100%
          );
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          label {
            position: absolute;
            font-size: 2rem;
            font-weight: 400;
            color: white;
            display: flex;
            align-items: center;
            bottom: 20px;
            left: 20px;
            @media screen and (max-width: 1300px) {
              font-size: 1.5rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 700px) {
              font-size: 1rem;
            }
            i {
              font-size: 1.5rem;
            }
          }
          p {
            font-size: 0.9rem;
            font-weight: 400;
            color: white;
            position: absolute;
            padding: 0.15rem 0.5rem;
            right: 10px;
            top: 10px;
            background: $green;
            border-radius: 5px;
            box-shadow: 0px 0px 10px 3px #4d4d4d2a;
          }
        }
        .img-section {
          position: relative;
          height: 100%;
          width: 100%;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }
        .display-text {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          background: rgba(0, 0, 0, 0.233);
          p {
            font-size: 1.5rem;
            font-weight: 500;
            color: #fff;
            padding: 0rem 2rem;
          }
        }
        &:hover,
        &:focus {
          transform: scale(1, 1);
          z-index: 1;
          .text-section {
            transition: all 0.4s ease-in-out;
            bottom: 0px;
          }
          .display-text {
            display: none;
          }
          img {
            transform: scale(1.05, 1.05);
            transition: all 0.4s ease-in-out;
          }
        }
      }
      .view-all {
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0px 0px 10px 3px #4d4d4d2a;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        background: url("@/assets/images/bgs-03.png") no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover,
        &:focus {
          transform: scale(1, 1);
          z-index: 1;
        }
        .img-section {
          position: relative;
          img {
            height: 250px;
            width: 100%;
            object-fit: cover;
            border-radius: 1.5rem;
            @media screen and (max-width: 1300px) {
              height: 200px;
            }
            @media screen and (max-width: 1100px) {
              height: 250px;
            }
            @media screen and (max-width: 700px) {
              height: 200px;
            }
          }
        }
        .display-text {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          p {
            font-size: 1.2rem;
            font-weight: 500;
            color: #1d1d1d;
          }
        }
      }
    }
  }

  .packages {
    position: relative;
    background: url("@/assets/images/bgs-02.png") no-repeat;
    background-size: cover;
    .header {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      @media screen and (max-width: 600px) {
        align-items: flex-start;
      }
      .desc {
        color: #333333;
        font-weight: 400;
        font-size: 1.1rem;
        @media screen and (max-width: 1200px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 900px) {
          font-size: 0.8rem;
        }
      }
    }
    .packages-body {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 2%;
      grid-row-gap: 2rem;
      margin: 2rem 0;
      flex-wrap: wrap;
      @media screen and (max-width: 1100px) {
        grid-template-columns: 48% 48%;
      }
      @media screen and (max-width: 600px) {
        grid-template-columns: 100%;
      }
      .package-item {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 3px #4d4d4d11;
        // overflow: hidden;
        transition: all 0.4s ease-in-out;
        &:hover,
        &:focus {
          z-index: 1;
        }
        .content {
          display: flex;
          flex-direction: column;
          position: relative;
          .img-section {
            position: relative;
            height: 250px;
            width: 100%;
            border-radius: 10px;
            padding: 1rem;
            img {
              height: 100%;
              width: 100%;
              border-radius: 10px;
              object-fit: cover;
              @media screen and (max-width: 1300px) {
                height: 200px;
              }
              @media screen and (max-width: 1100px) {
                height: 250px;
              }
              @media screen and (max-width: 700px) {
                height: 200px;
              }
            }
            .specs {
              background-color: #fff;
              border-radius: 100px;
              padding: 0.4rem 1rem;
              position: absolute;
              bottom: -10px;
              width: 80%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #2b2b2b;
              font-size: 0.8rem;
              box-shadow: 0px 0px 10px 3px #0000003b;
              .region {
                display: flex;
                align-items: center;
                column-gap: 0.25rem;
              }
            }
          }
          .text-section {
            padding: 0 1rem;
            padding-top: 2rem;
            @media screen and (max-width: 1300px) {
              padding: 0 0.75rem;
              padding-top: 1.5rem;
            }
            .package-title {
              color: #1d1d1d;
              font-weight: 500;
              font-size: 1.2rem;
              @media screen and (max-width: 1300px) {
                font-size: 1rem;
              }
            }
          }
          hr {
            align-self: center;
            width: 90%;
            border: none;
            border-top: 1px #eee solid;
            margin: 1rem 0;
          }
          .content-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 1rem;
            .money {
              color: $green;
              font-size: 1.2rem;
              font-weight: 500;
              @media screen and (max-width: 1300px) {
                font-size: 1.25rem;
              }
              span {
                color: #000000;
                font-size: 0.75rem;
                font-weight: 400;
              }
              p {
                font-size: 1.6rem;
                font-weight: 600;
              }
            }
            .book {
              .book-btn {
                color: #fff;
                font-size: 0.8rem;
                font-weight: 600;
                padding: 0.75rem 1.5rem;
                background: $green;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.4s ease-in-out;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                i {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .packages-footer {
      padding: 2rem 0;
    }
  }
}
</style>